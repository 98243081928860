<template>
  <div>
    <UserPage ref="userprofile" />
  </div>
</template>

<script>
import UserPage from "@/view/pages/admin/users/comp/UserPage";
export default {
  name: "profile",
  components: {
    UserPage
  },
  data() {
    return {};
  },
  beforeMount() {},
  mounted() {
    this.$root.title =
      this.$user.getCurrentUser().first_name +
      " " +
      this.$user.getCurrentUser().last_name;
    this.$refs.userprofile.setUser(this.$user.getCurrentUser(), true);
  },
  methods: {},
  computed: {}
};
</script>

<style></style>
